:root {
    --card-bg-color: radial-gradient(
            149.88% 127.9% at -8.62% 0%,
            #3d4148 6.56%,
            #6b7a9c 100%
    );
    --primary-bg-color: #E0E0E0;
    --primary-grey: #3b3b3e;
    --secondary-grey: #F2F2F2;
    --primary-dark: #333333;

}

.wrapper {
    /*
        background: var(--primary-bg-color);*/
    /*
    background: radial-gradient(126.85% 126.85% at 12.55% 1.67%, #69779A 12.45%, #6E7E9F 45.34%, #2F2F2F 89.7%);
    */
    overflow-y: auto;
    position: relative;
    height: 100vh;
    color: var(--secondary-grey);

}

.container {
    /*max-width: 1920px;*/
    position: relative;
    margin: 0 auto;
    overflow: hidden;

}

.section {
    padding: 0 80px;
}

.content-container {
    max-width: 1920px;
    width: 100%;
}

.services h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.03em;
}

h1{
    font-weight: 700;
    font-size: 50px;
    line-height: 125%;
    /* identical to box height, or 62px */
    letter-spacing: -0.03em;
    cursor: default;
}
p{
    cursor: default;
}
h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    /* identical to box height, or 35px */

    display: flex;
    align-items: center;

    /* Black */
    cursor: default;

}
h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.03em;
    cursor: default;
}
button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 166px;
    height: 60px;
    border-radius: 30px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    letter-spacing: -0.02em;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    color:#333333;
}

button:hover {
    cursor: pointer;
}

.light {
    background: var(--secondary-grey);
    color: var(--primary-dark);
}
.light:hover {
    background: #fff;

}

.dark {
    background: var(--primary-dark);
    color: var(--secondary-grey);
}
.dark:hover {
    background: var(--primary-grey);
}

.buttons-block {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 40px;
}
.promo{
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.promo-content{
    background: #3b3b3b;
    height: 48px;
    border-radius: 14px 14px 0 0;

    display: grid;
    grid-template-columns: 48px 1fr 48px;
    align-items: center;
    justify-items: center;
    width: 100%;
    max-width: 1680px;
    cursor: default;
    white-space: nowrap;
}


@media screen and (max-width: 1600px) {
    .container {

    }
}

@media screen and (max-width: 1024px) {
    .promo{

    }
    .promo-content{
        grid-template-columns:  auto;
        overflow: hidden;
        font-size: 13px;
    }
    h1{
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        letter-spacing: -0.03em;
    }
    .light {
        background: var(--secondary-grey);
        color: #333333;
        text-decoration: none;
    }

    .dark {
        background: var(--primary-dark);
        color: var(--secondary-grey);
        text-decoration: none;
    }
    .section {
        padding: 15px;
        display: flex;
        flex-direction: column;

    }

    .wrapper {
        overflow-x: hidden;
        background: var(--primary-bg-color);
    }

    .container {
        position: unset;
        width: 100%;

    }
    button{
        height: 50px;
    }

}

