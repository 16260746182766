.small-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-loader__spinner {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}

.small-loader__spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 2px;
    border: 2px solid #67799c;
    border-radius: 50%;
    animation: small-loader__bounce 1s infinite ease-in-out;
}

.small-loader__spinner .small-loader__bounce1 {
    animation-delay: -0.32s;
}

.small-loader__spinner .small-loader__bounce2 {
    animation-delay: -0.16s;
}

/* Add a second color to the inner circle */
.small-loader__spinner .small-loader__bounce3 {
    border-color: #bbad92;
}

@keyframes small-loader__bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
