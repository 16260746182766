.burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3B3B3E;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 50px 16px;
    padding-top: 28px;
    box-sizing: border-box;
    overflow: hidden;
}
.burger-menu .logo{
    height: 16px;
    width: auto;
}
.burger-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
}

.burger-menu-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    flex-grow: 1;
    padding: 24px;
}

.burger-menu-item {
    margin-bottom: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: 0.3s;
    padding-bottom: 26px;
    border-bottom: 1px solid #828282;
}
.burger-menu-footer-item{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.burger-menu-item a {
    color: #F2F2F2;
    text-decoration: none;
    font-size: 28px;
    line-height: 125%;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0;

}
.burger-menu-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.burger-menu-footer img {
    width: 36px;
    height: 36px;
   margin : 0 20px;
}
@media (min-width: 768px) {
    .burger-menu {
        display: none;
    }
}