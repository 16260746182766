.custom-dropdown {
    position: relative;
    cursor: pointer;
    background-color: #fff;
    align-items: center;
    border: none;
    border-radius: 12px;
    color: #3b3b3b;
    display: flex;
    font-weight: 700;
    justify-content: center;
    margin: 6px;
    text-transform: uppercase;
    width: 168px;

    font-size: 16px;
    font-family: 'Inter', sans-serif;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.selected-option{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    overflow: visible;
}
.selected-option:hover {
    opacity: 0.8;

}
.custom-dropdown button {
  /*  display: flex;
    justify-content: space-between;
    align-items: center;

   width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #a9a7a7;
    font-size: 16px;
    cursor: pointer;*/
}

.custom-dropdown svg {
    width: 30px !important;
    height: 30px !important;
    overflow: visible;
}

.custom-dropdown button img {
   /* width: 20px;
    height: 20px;
    margin-right: 10px;*/
}

.custom-dropdown button:hover {
    border-color: #aaa;
}

.custom-dropdown ul {
    position: absolute;
    top: 85%;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    border-top: none;
    border-radius: 0 0 12px 12px;
    background-color: #fdfdfe;
    z-index: 1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-dropdown li {
   /* padding: 0 6px;*/
    font-size: 16px;
    cursor: pointer;
}

/*.custom-dropdown li:hover {
   !* opacity: 0.8;*!
    background-color: rgba(203, 203, 203, 0.66);

}*/

.custom-dropdown .dropdown-item {
    display: flex;
    align-items: center;
}

.custom-dropdown .dropdown-item img {

}

.selected-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    width: 100%;
    margin: 0 10px;
}
.selected-option svg{
    overflow: visible;

}
.options-list {
    /*    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6px;*/
}

.options-list .option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
   /* margin: 12px 6px;*/
    gap: 6px;
    padding: 8px 10px;
   /* min-height: 20px;*/
    border-top: 0.4px dotted #ccc;
}
.options-list .option:hover {
    /* opacity: 0.8;*/
    background-color: rgba(203, 203, 203, 0.66);

}