.calculator {
    width: 343px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: rgba(59, 59, 62, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 14px;
    padding: 32px;
}

.calculator-header {
    align-self: start;
}
.reverse-button{
    align-items: center;
    background-color: rgba(59,59,62,.4);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: center;
    margin-right: 0;
    padding: 7px 7px 5px;
    width: auto;
}
.reverse-button:hover{
    background-color: rgba(59,59,62,.6);
}
.calculator h2 {
    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    margin-top: unset;
}

.calculator-input-group {
    display: flex;
    flex-direction: column;

    align-items: start;
    width: 100%;
}

.calculator-input-group label {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #e0e0e0;
    margin-bottom: 8px;
}

.input-wrapper {
    background-color: var(--secondary-grey);
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    padding: 2px;
    width: 100%;
}

.calculator-input-group input {
    align-items: center;
    background-color: #f2f2f2;
    border: none;
    border-radius: 14px;
    color: #3b3b3e;
    display: flex;
    font-family: Consolas,monaco,monospace;
    font-size: 32px;
    padding: 16px;
    width: calc(100% - 28px);
}

.calculator-input-group input:focus {
    outline: none;
}

.input-wrapper select {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 12px;
    color: #3b3b3b;
    display: flex;
    font-weight: 700;
    justify-content: center;
    margin: 6px;
    text-transform: uppercase;
    width: 128px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
}
.calculator-delimiter{
    text-transform: uppercase;
}
.coin-price{

    display: flex;
    flex-direction: row;
gap: 5px;
    margin-left: 9px;
    margin-right: 10px;
}
.calc-error{
    color: #FFB1B0;
    font-size: 14px;
    margin-top: 0;
    margin-left: 9px;
    width: 100%;

    min-width: max-content;
    text-transform: none;
    text-decoration: underline;

}
.calc-error:hover{
    cursor: pointer;
    color: #ffcbcb;
}
.input-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.input-wrapper select:focus {
    outline: none;
}

.input-wrapper select:hover {
    cursor: pointer;
}

.input-wrapper select option {
    background: #fff;
    color: #3b3b3b;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Inter', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
.input-wrapper input::-webkit-outer-spin-button,
.input-wrapper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input-wrapper input[type=number] {
    -moz-appearance: textfield;
}

.calculator-delimiter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 16px 0;

    width: 100%;
}

.calculator-delimiter-line {
    background-color: #f2f2f2;
    content: "-";
    height: 1px;
    margin: 0 9px;
    width: 100%;
}

.calculator-networks {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin: 16px 0;
}

.calculator-networks label {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 15px */
    margin-top: 16px;
    margin-bottom: 8px;
}

.calculator-networks-buttons {

    display: flex;
    flex-direction: row;
    align-items: center;

    background: rgba(59, 59, 62, .6);
    width: calc(100% + 32px);
    height: 54px;
    right: 32px;
    position: relative;
    padding-left: 32px;
}

.calculator-networks-button {
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    color: #f2f2f2;
    height: 50px;
    margin-right: 30px;
    position: relative;
    width: 75px;
}

.calculator-networks-button:hover {
    cursor: pointer;
    color: #fff;
}

.calculator-networks-button.active {
   /* border-bottom: 5px solid #fff;
    border-radius: 5px;*/
}
.calculator-networks-button.active::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;;
}
.calculator-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.calculator-footer-button {
    width: 100%;
    background-color: #f2f2f2;
    border: none;
    border-radius: 14px;
    color: #3b3b3e;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: center;

    margin: 16px 0 0 0;
    padding: 16px;
}
.calculator-footer-button:hover {
    cursor: pointer;
    background-color: #fff;
}
.mobile {
    display: none;
}

@media (max-width: 1024px) {
    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }

    .calculator {
        width: 75%;
        max-width: 350px;
        margin: unset;
        padding: 28px;

    }

    .calculator h2 {
        font-size: 24px;
        line-height: 28px;
    }

    .calculator-input-group input {
        font-size: 24px;
    }

    .calculator-networks-buttons {
        width: calc(100% + 28px);
        right: 28px;
        padding-left: 28px;
    }

    .calculator-networks-button {
        font-size: 14px;
        margin-right: 16px;
        width: 60px;
    }

    .calculator-footer-button {
        font-size: 14px;
        padding: 12px;
    }
}
