.main-section {

    background: radial-gradient(126.85% 126.85% at 12.55% 1.67%, #69779A 12.45%, #6E7E9F 45.34%, #2F2F2F 89.7%);

    height: 100vh;
    max-height: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.header {
    margin-top: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    max-width: 14vw;
    height: auto;
    cursor: pointer;
}

.logo svg {
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.menu a {
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: #fff;

    line-height: 125%;
}
.desktop-menu-item{
    position: relative;
    cursor: pointer;
}
.desktop-menu-item:hover::after {
    cursor: pointer;
   /* border-bottom: 3px solid #fff;
    border-radius: 2px;*/
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;;
}



.main-section .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 7%;
    margin-top: 80px;
}
.main-text{
    margin-right: 7%;
}

.main-section h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 110%;
    max-width: 739px;
}

.main-section p {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
   max-width: 441px;
}

.bubble {
    /*max-width: 1024px;*/
    width: 1920px;
    position: absolute;
    top: -16%;
    left: 10%;
}

.burger {
    display: none;
}

.arrows {
    margin-top: 10vh;
}
.language{
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
       text-transform: uppercase;
    color: #FFFFFF;
    gap:10px;
    cursor: pointer;
}
.watch{
    width: 191px;
}
.open-app{
    width: 191px;
}

@media screen and (max-width: 1024px) {
    .main-text{
        margin-right: 0;
    }
    .burger-button {
        display: block;
        cursor: pointer;
        position: relative;

    }


    .burger {
        display: block;
        margin-right: 16px;
    }

    .main-section {
        height: auto;
        padding: unset;
        margin-bottom: unset;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow-x: hidden;
        background: radial-gradient(126.85% 126.85% at 12.55% 1.67%, #69779A 12.45%, #6E7E9F 45.34%, #2F2F2F 89.7%);

        border-radius: 0 0 20px 20px;

    }

    .main-section .content-container {
        background-blend-mode: overlay;
        background-image: url(../../images/bg-mask.svg);
        /*!*background-position: 50% 0;*!*/
        background-repeat: repeat;
        /*background-size: cover;*/
        content: " ";
        display: block;
        /* height: 100%;*/
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .main-section .main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 4vh;
        padding-right: unset;
    }

    .main-section h1 {
        font-weight: 700;
        font-size: 32px;
        width: 100%;
        text-align: center;
        margin-top: unset;
        margin-bottom: unset;
    }

    .main-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-section p {
        font-weight: 600;
        font-size: 18px;
        line-height: 125%;
        width: 80%;
        margin-top: 23px;
        margin-bottom: 32px;
        text-align: center;
    }

    .menu {
        display: none;
    }

    .bubble {
        width: 1024px;
        height: auto;
        left: -78%;
        top: -10%;


    }

    .logo {
        max-width: 159px;
        height: auto;
        margin-left: 16px;
    }

    .logo img {

        height: 16px;

        width: auto;
        margin: unset;
    }

    .burger img {
        width: auto;
        height: 16px;
    }

    .language {
        display: none;
    }

    .arrows {
        display: none;

    }

    .buttons-block {
        margin-bottom: 52px;
        flex-wrap: wrap;
        justify-content: center;

    }
    .buttons-block button{
        height: 50px;
width: 160px;
    }
}
